<template>
  <v-card elevation="0" class="ma-5">
    <section v-if="ebook">
      <v-row>
        <v-col
          cols="12"
          lg="3"
          md="4"
          sm="6"
          xs="6"
          v-for="(ebook, idx) in ebook.data"
          :key="idx"
        >
          <v-card
            class="radius-card artcle_card__ ma-2 pointer__"
            width="210px"
            height="270px"
            @click.self="toDetail(`/ebook/${ebook.id}/${ebook.visible}`)"
          >
            <div class="temp_img__">
              <img
                :src="
                  `${env}/ebook/upload/${ebook.user_id}/image/${ebook.image}`
                "
                class="img-fit scale"
                height="150px"
                width="100%"
                alt="cover"
              />
            </div>
            <div class="action__ px-2 d-flex justify-end">
              <v-btn
                fab
                x-small
                dark
                class="mr-2"
                depressed
                color="blue"
                content="View Ebook"
                :to="`/ebook/${ebook.id}/${ebook.visible}`"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }"
                ><v-icon small>mdi-eye</v-icon></v-btn
              >

              <v-btn
                fab
                x-small
                dark
                class="mr-2"
                @click="updateItem(ebook)"
                depressed
                color="green"
                content="Edit Ebook"
                v-if="ebook.user_id == id"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }"
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
              <v-btn
                fab
                x-small
                dark
                depressed
                @click="deleteItem(ebook.id)"
                color="red"
                content="Delete Ebook"
                v-if="ebook.user_id == id"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }"
                ><v-icon small>mdi-delete</v-icon></v-btn
              >
            </div>
            <div class="px-2">
              <h4>{{ ebook.judul }}</h4>
              <span v-snip="3" class="_isi" v-html="ebook.isi"> </span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <section class="pa-2 d-flex justify-center" v-if="!ebook.data.length">
        <div>
          <img src="../../assets/img/404.svg" height="100px" alt="404" />
          <h3 class="text-center">No Data Found!</h3>
        </div>
      </section>
    </section>
    <div style="width: 100%" v-if="loading">
      <v-row>
        <v-row>
          <v-col md="3" lg="3" sm="6" xs="6" v-for="i in 4" :key="i">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-row>
    </div>
    <!-- <EditArticle
      v-bind:editArticle="editArticle"
      v-bind:item="item"
      @close="closeDialog"
      @refetch="fetch"
    /> -->
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
// import EditArticle from "./Modal/editArticle.vue";

export default {
  //   components: { EditArticle },
  name: "EbookPublic",
  data() {
    return {
      editArticle: false,
      item: null,
      page: 1,
      limit: 10,
      find: "",
    };
  },
  computed: {
    ...mapState({
      ebook: (state) => state.library.public_ebook,
      loading: (state) => state.library.loading,
      env: (state) => state.API_URL,
      id: (state) => state.id,
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    toDetail(path) {
      this.$router.push(path);
    },
    fetch() {
      let data = {
        page: this.page,
        limit: this.limit,
        find: this.find,
      };
      this.$store.dispatch("library/publicEbook", data);
    },
    viewTo(id) {
      this.$router.push(`/ebook/${id}/view`);
    },
    closeDialog() {
      this.editArticle = false;
    },
    updateItem(item) {
      this.item = item;
      this.editArticle = true;
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("library/deleteArticle", { id: id })
            .then((data) => {
              this.fetch();
              Swal.fire({
                icon: "success",
                title: data.message,
                showConfirmButton: false,
                position: "top",
                timer: 3000,
                toast: true,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
._isi {
  line-height: 1.2;
}
.artcle_card__ {
  overflow: hidden;
}
.temp_img__ {
  overflow: hidden;
  min-height: 150px;
}
.scale {
  transition: 0.5s;
}
.scale:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.action__ {
  margin-top: -20px;
}
.pointer__ {
  cursor: pointer;
}
</style>
