<template>
  <v-card elevation="0" class="mx-5 pb-5">
    <section v-if="article">
      <v-row>
        <v-col cols="5">
          <p class="mb-0 small_txt grey--text">Search</p>
          <v-text-field
            solo
            dense
            hide-details
            placeholder="search"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <p class="mb-0 small_txt grey--text">Sort By</p>

          <v-select
            :items="listSort"
            item-text="txt"
            hide-details
            dense
            label="Sot"
            solo
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="3"
          md="4"
          sm="6"
          xs="6"
          v-for="(article, idx) in article.data"
          :key="idx"
        >
          <v-card
            class="radius-card artcle_card__ ma-2 pointer__"
            height="270px"
            @click.self="toDetail(`/article/${article.id}/view`)"
          >
            <div class="temp_img__">
              <img
                :src="
                  `${env}/artikel/upload/${article.user_id}/${article.image}`
                "
                class="img-fit scale"
                height="150px"
                width="100%"
                alt="cover"
              />
            </div>
            <div class="action__ px-2 d-flex justify-end">
              <v-btn
                fab
                x-small
                dark
                class="mr-2"
                depressed
                color="blue"
                content="View Article"
                :to="`/article/${article.id}/view`"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }"
                ><v-icon small>mdi-eye</v-icon></v-btn
              >

              <v-btn
                fab
                x-small
                dark
                class="mr-2"
                @click="updateItem(article)"
                depressed
                color="green"
                content="Edit Article"
                v-if="article.user_id == id"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }"
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
              <v-btn
                fab
                x-small
                dark
                depressed
                @click="deleteItem(article.id)"
                color="red"
                content="Delete Article"
                v-if="article.user_id == id"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 100,
                }"
                ><v-icon small>mdi-delete</v-icon></v-btn
              >
            </div>
            <div class="px-2">
              <h4 v-snip="2">{{ article.judul }}</h4>
              <span v-snip="2" class="_isi" v-html="article.isi"> </span>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <section
        class="pa-2 d-flex justify-center"
        style="width: 100%"
        v-if="!article.data.length"
      >
        <div>
          <img src="../../assets/img/404.svg" height="100px" alt="404" />
          <h3 class="text-center">No Data Found!</h3>
        </div>
      </section>
    </section>
    <div style="width: 100%" v-if="loading">
      <v-row>
        <v-col cols="3" md="3" v-for="i in 8" :key="i">
          <div>
            <v-skeleton-loader elevation="1" type="card"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </div>
    <EditArticle
      v-bind:editArticle="editArticle"
      v-bind:item="item"
      @close="closeDialog"
      @refetch="fetch"
    />
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import EditArticle from "./Modal/editArticle.vue";

export default {
  components: { EditArticle },
  name: "ArticlePublic",
  data() {
    return {
      editArticle: false,
      item: null,
      page: 1,
      limit: 10,
      find: "",
      listSort: [
        {
          txt: "Most Liked",
          val: "like",
        },
        {
          txt: "Most Viewed",
          val: "view",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      article: (state) => state.library.article,
      loading: (state) => state.library.loading,
      env: (state) => state.API_URL,
      id: (state) => state.id,
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    toDetail(path) {
      this.$router.push(path);
    },
    fetch() {
      let data = {
        page: this.page,
        limit: this.limit,
        find: this.find,
      };
      this.$store.dispatch("library/publicArticle", data);
    },
    viewTo(id) {
      this.$router.push(`/article/${id}/view`);
    },
    closeDialog() {
      this.editArticle = false;
    },
    updateItem(item) {
      this.item = item;
      this.editArticle = true;
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("library/deleteArticle", { id: id })
            .then((data) => {
              this.fetch();
              Swal.fire({
                icon: "success",
                title: data.message,
                showConfirmButton: false,
                position: "top",
                timer: 3000,
                toast: true,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>

<style scoped>
._isi {
  line-height: 1.2;
}
.artcle_card__ {
  overflow: hidden;
}
.temp_img__ {
  overflow: hidden;
  min-height: 150px;
}
.scale {
  transition: 0.5s;
}
.scale:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.action__ {
  margin-top: -20px;
}
.pointer__ {
  cursor: pointer;
}
</style>
