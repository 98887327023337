<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top pa-3 _full-w">
      <v-card class="radius-card pa-3">
        <v-tabs v-model="tab" class="mb-5">
          <v-tab href="#tab-1">Article</v-tab>
          <v-tab href="#tab-2">E-book</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" style="background: none">
          <v-tab-item value="tab-1">
            <section>
              <v-btn
                outlined
                color="green"
                v-if="!ark"
                @click="ark = true"
                rounded
              >
                <v-icon>mdi-reply</v-icon> <b>Back</b></v-btn
              >
            </section>
            <ListArticle v-if="ark" />
            <CreateArticle v-if="!ark" />
          </v-tab-item>

          <v-tab-item value="tab-2">
            <v-btn
              outlined
              color="green"
              v-if="!book"
              @click="book = true"
              rounded
            >
              <v-icon>mdi-reply</v-icon> <b>Back</b></v-btn
            >
            <ListEbook v-if="book" />
            <CreateEbook v-if="!book" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import CreateArticle from "../../components/Psycholog/createArticle.vue";
import CreateEbook from "../../components/Psycholog/createEbook.vue";
import ListArticle from "../../components/Psycholog/listArticlePublic";
import ListEbook from "../../components/Psycholog/listEbookPublic";
export default {
  components: {
    CreateArticle,
    CreateEbook,
    ListArticle,
    ListEbook
  },
  name: "Library",
  data() {
    return {
      tab: "tab-1",
      ark: true,
      book: true
    };
  }
};
</script>

<style></style>
