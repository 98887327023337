var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-5",attrs:{"elevation":"0"}},[(_vm.ebook)?_c('section',[_c('v-row',_vm._l((_vm.ebook.data),function(ebook,idx){return _c('v-col',{key:idx,attrs:{"cols":"12","lg":"3","md":"4","sm":"6","xs":"6"}},[_c('v-card',{staticClass:"radius-card artcle_card__ ma-2 pointer__",attrs:{"width":"210px","height":"270px"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.toDetail(("/ebook/" + (ebook.id) + "/" + (ebook.visible)))}}},[_c('div',{staticClass:"temp_img__"},[_c('img',{staticClass:"img-fit scale",attrs:{"src":(_vm.env + "/ebook/upload/" + (ebook.user_id) + "/image/" + (ebook.image)),"height":"150px","width":"100%","alt":"cover"}})]),_c('div',{staticClass:"action__ px-2 d-flex justify-end"},[_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100,
              }),expression:"{\n                arrow: true,\n                arrowType: 'round',\n                animation: 'fade',\n                theme: 'light',\n                maxWidth: 100,\n              }"}],staticClass:"mr-2",attrs:{"fab":"","x-small":"","dark":"","depressed":"","color":"blue","content":"View Ebook","to":("/ebook/" + (ebook.id) + "/" + (ebook.visible))}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1),(ebook.user_id == _vm.id)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100,
              }),expression:"{\n                arrow: true,\n                arrowType: 'round',\n                animation: 'fade',\n                theme: 'light',\n                maxWidth: 100,\n              }"}],staticClass:"mr-2",attrs:{"fab":"","x-small":"","dark":"","depressed":"","color":"green","content":"Edit Ebook"},on:{"click":function($event){return _vm.updateItem(ebook)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e(),(ebook.user_id == _vm.id)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100,
              }),expression:"{\n                arrow: true,\n                arrowType: 'round',\n                animation: 'fade',\n                theme: 'light',\n                maxWidth: 100,\n              }"}],attrs:{"fab":"","x-small":"","dark":"","depressed":"","color":"red","content":"Delete Ebook"},on:{"click":function($event){return _vm.deleteItem(ebook.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e()],1),_c('div',{staticClass:"px-2"},[_c('h4',[_vm._v(_vm._s(ebook.judul))]),_c('span',{directives:[{name:"snip",rawName:"v-snip",value:(3),expression:"3"}],staticClass:"_isi",domProps:{"innerHTML":_vm._s(ebook.isi)}})])])],1)}),1),(!_vm.ebook.data.length)?_c('section',{staticClass:"pa-2 d-flex justify-center"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/img/404.svg"),"height":"100px","alt":"404"}}),_c('h3',{staticClass:"text-center"},[_vm._v("No Data Found!")])])]):_vm._e()],1):_vm._e(),(_vm.loading)?_c('div',{staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-row',_vm._l((4),function(i){return _c('v-col',{key:i,attrs:{"md":"3","lg":"3","sm":"6","xs":"6"}},[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)}),1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }